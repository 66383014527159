import React from "react";

import Menu from "menu";


import { Container, Typography, Grid, Paper, Box} from '@mui/material';

import Form from "../../form"

import { BannerSoftware } from "Software/bannerSoftware";
import { Paragraph } from "toolbox";

import { useTranslation } from 'react-i18next';


import { SoftwareHeader } from "../software";

import { AddAlert, Key, InsertLink, EventNote, Https, FontDownload, Print } from "@mui/icons-material"
import Slider from 'react-slick';

import './tasky.css'



const Carousel_v2 = () => {
  const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true, 
      infinite: true
  };

  const images = [
   //   "/res/tasky/Tasky_1_TODO.png",
      "/res/tasky/Tasky_2_CONTACTS.png",
      "/res/tasky/Tasky_3_WORK.png",
      "/res/tasky/Tasky_4_Notes.png",
      "/res/tasky/Tasky_5_Links.png",
   //   "/res/tasky/Tasky_6_Purchases.png"
  ];

  return (
      <Box sx={{ width: '80%', margin: 'auto' }}>
          <Slider {...settings}>
              {images.map((image, index) => (
                  <div key={index}>
                      <img src={image} alt={`Slide ${index}`} style={{ width: '100%' }} />
                  </div>
              ))}
          </Slider>
      </Box>
  );
};




export default (() => {

  const { t, i18n } = useTranslation("tasky");


  const tasks = [
    [t("tasks.name"), "/res/tasky/task.png", t("tasks.desc")],
    [t("labels.name"), "/res/tasky/label.png", t("labels.desc")],
    [t("links.name"), "/res/tasky/link.png", t("links.desc")],
    [t("lists.name"), "/res/tasky/lists.png", t("lists.desc")],
    [t("contact.name"), "/res/tasky/contact.png", t("contact.desc")],
    [t("scripts.name"), "/res/tasky/script.png", t("scripts.desc")],
  ];

  const features = [
    [t('alerts.name'), <AddAlert />, t('alerts.desc')],
    [t('passwords.name'), <Key />, t('passwords.desc') ],
    [t('memo.name'), <EventNote />, t('memo.desc') ],
    [t('crypt.name'), <Https />, t('crypt.desc') ],
    [t('format.name'), <FontDownload />, t('format.desc')],
    [t('print.name'), <Print />, t('print.desc') ],
    [t('importBookmarks.name'), <InsertLink />, t('importBookmarks.desc')],
    [t('importKeepass.name'),  <img src="/res/links/keepass.png" width="30px"/>, t('importKeepass.desc')],
  ];

	return <Box>
		<Menu idx={1}/>

		<BannerSoftware selected="tasky" backgroundColor={true}/>

		<Container>

      <SoftwareHeader  title="Tasky"  icon="/res/tasky/Tasky_ico.png" program="/res/tasky/Tasky_1.5_Setup.zip" t={t} type={0}/>


    <Box display="flex" sx={{mt:6}}>
      <Box width="40%">
        <Paragraph  text='text3' t={t} />
      </Box>
      <Box  width="60%">
        <Carousel_v2 />
      </Box>
    </Box>



		<Typography variant="h4" sx={{ margin: 4 }}>{t('taskTypes')}</Typography>
    
    <Grid container spacing={2} justifyContent="center" direction="row">
				{tasks.map(([k,icon, v]) => (
					<Grid item xs={2} key={k}>
					<Paper elevation={3}  
						style={{ 
							height: '200px', // Hauteur fixe
							display: 'flex', 
							alignItems: 'center', 
						//	justifyContent: 'center',
							textAlign: 'center' ,
							flexDirection: 'column',
						}}
					>
						<Typography variant="h5">{k}</Typography>
            <img src={icon} width="40px"/>
						<Typography>{v}</Typography>
					</Paper>
					</Grid>
				))}
			</Grid>






		<Typography variant="h4" sx={{ margin: 4 }}>{t('features')}</Typography>

    <Grid container spacing={2} justifyContent="center" direction="row">
				{features.map(([k,icon, v]) => (
					<Grid item xs={3} key={k}>
					<Paper elevation={3}  
						style={{ 
							height: '160px', // Hauteur fixe
							display: 'flex', 
							alignItems: 'center', 
						//	justifyContent: 'center',
							textAlign: 'center' ,
							flexDirection: 'column',
						}}
					>
						<Typography variant="h5">{k}</Typography>
           
            {icon}
						<Typography>{v}</Typography>


					</Paper>
					</Grid>
				))}
			</Grid>




			<p>
      Tasky permet de:
      </p>
      <div>
      <ul>
        <li>créer des tâches. Les différents types de tâches sont accessibles avec le clic droit de la souris:</li>
        <ul>
          <li><b>Tâches</b> à cocher</li>
          <li><b>Etiquettes</b>: texte non cochable</li>
          <li><b>Lien</b> (vers un site Internet, un email ou un fichier/dossier sur le PC)</li>
          <li><b>Liste de tâches</b>: lien vers une autre liste de tâches dans Tasky</li>
          <li><b>Contact</b>: pour enregistrer un email, un téléphone, une adresse, rappeler un anniversaire...</li>
          <li><b>Script</b>: pour lancer rapidement des commandes Windows et automatiser des tâches</li>
        </ul>
        <br />
        <li>organiser les différentes tâches en lignes et en arbres de tâches</li>
        <br />
        <li>Associer à chaque tâche:</li>
        <ul>
          <li>un <b>memo</b> complémentaire</li>
          <li>des <b>liens</b> Internet, ou vers un fichier/dossier</li>
          <li>enregistrer un <b>mot de passe</b> (ou en générer un de façon aléatoire grâce au générateur inclus)</li>
          <li>programmer un <b>rappel</b></li>
        </ul>
        <br />
        <li>personnaliser les tâches (couleur, gras, italique, couleur de fond)</li>
        <li>organiser les tâches en les déplaçant intuitivement avec la souris</li>
        <li>créer autant de listes que souhaité</li>
        <li>crypter des listes de tâches pour sécuriser vos mots de passe par exemple</li>
        <li>filter les tâches à afficher</li>
        <li>exporter les tâches vers le presse-papier ou les imprimer</li>
      </ul>
      </div>
    

	
        {/* FORMULAIRE DE CONTACT */}
		    <Form title='[Tasky]'/>

		</Container>
	</Box>
});