import React from "react";
import { useState } from 'react'

import Menu from "menu";
import Banner from "banner";

import { Container, Typography, Grid, Paper, Box , Button, LinearProgress, Avatar, Link, Stack } from '@mui/material';


import Form from "../../form"


import { BannerSoftware } from "Software/bannerSoftware";


import { Platform, Category, SoftwareHeader } from "../software";


import { useTranslation } from 'react-i18next';



export default (() => {

	const { t, i18n } = useTranslation("rubikTeacher");

	const tLayer = useTranslation("methodLayer");
	const tFridrich = useTranslation("methodFridrich");


	return <Box>
		<Menu idx={1}/>

		<BannerSoftware selected="rubikTeacher" backgroundColor={true}/>

		<Container>
			<SoftwareHeader  title="Rubik Teacher"  icon="/res/rubikTeacher/rubikTeacherIcon.png" program="/res/rubikTeacher/RubikTeacher_v3.1.exe" t={t} type={1}/>
		</Container>

		<Container>
			<Box display="flex"
				justifyContent="center" // Centre horizontalement
				alignItems="center" // Centre verticalement
				sx={{m: 5}}
			>
				<iframe width="720" height="480"
					src="https://www.youtube.com/embed/Ig7HRs3gBnk">
				</iframe>
			</Box>
		</Container>


		<Container>
			<Grid container spacing={2} mb={5}>
				<Grid item xs={4} md={6}  sx={{backgroundColor: "#fcf3cf", padding: '16px'}}>
					<img  src="/res/rubikTeacher/rt_edition.png" className="responsive-image" style={{  borderRadius:'10px' }}/>
				</Grid>
				<Grid item xs={4} md={6}  sx={{backgroundColor: "#fcf3cf"}}>
					<Typography variant="h1_software">{t('modeSolve')}</Typography>
					<Typography>{t('modeSolveDesc')}</Typography>
				</Grid>
			</Grid>

			<Grid container spacing={2}>

				<Grid item xs={4} md={6}  sx={{backgroundColor: "#d5f5e3"}}>
					<Typography variant="h1_software">{t('modeLearn')}</Typography>
					<Typography>{t('modeLearnDesc')}</Typography>
					<ul>
					<li>Couche par couche (débutant)</li>
					<li>Méthode Jessica Fridrich (avancée)</li>
				</ul>
				</Grid>
				<Grid item xs={4} md={6} sx={{backgroundColor: "#d5f5e3", padding: '16px'}}>
					<img  src="/res/rubikTeacher/rt_learn.png" className="responsive-image" style={{  borderRadius:'10px' }}/>
				</Grid>
			</Grid>

			<div>
				<ul>
					<li>
						<b>Méthode <em>Couche par couche</em></b> : pour les débutants qui permet de résoudre en une centaine de mouvements
					</li>
					<li>
						<b>Méthode <em>Fridrich</em></b> : méthode experte, qui permet de résoudre le cube en moins de 60 mouvements et très largement utilisée en compétition pour descendre sous la barre des 10 secondes!
					</li>
				</ul>
			</div>

			<div>
				<h2 class="text-primary">Présentation du logiciel</h2>
			</div>

			<ol>
				<li>
					<b>Cube initial</b>
					<br />
					Cette zone sert à coloriser le cube. La palette permet de "peindre" le cube sur la vue éclatée tandis que les différents boutons servent respectivement à:
					<ol type="a">
						<li>Remplir le cube en mode "résolu"</li>
						<li>Vider complètement le cube</li>
						<li>Vérifier la validité du cube</li>
					</ol>
				</li>
				<li>
					<b>Mélange aléatoire</b>
					<p>Choisissez le nombre de mouvements aléatoires avant de mélanger le cube</p>
				</li>
				<li>
					<b>Mouvements</b>
					<p>Cliquez sur l'un des 36 mouvements possibles pour animer le cube 3D, le mélanger, et éventuellement, le résoudre!</p>
				</li>
				<li>
					<b>Zone d'affichage du cube 3D</b>
					Les différents boutons servent respectivement à:
					<ol type="a">
						<li>recentrer le cube à sa position d'origine</li>
						<li>Animer le cube on/off</li>
						<li>Nettoyer la zone de texte</li>
					</ol>
				</li>
				<li>
					<b>Zone d'affichage de la solution et des différents algorithmes de résolution</b>
				</li>
				<li>
					<b>Cube Cible</b>: 
					<p>Décrit la configuration visée aux différentes étapes de résolution</p>
				</li>

				<li>
					<b>Résolution</b>
					Les boutons servent à:
					<ol type="a">
						<li>Comparer les différentes solutions disponibles pour voir combien de mouvements sont nécessaires avec chacune des méthodes</li>	
						<li>Résoudre le cube!</li>
					</ol>
				</li>

				<li>
					<b>Lecteur d'algorithmes et contrôle de la vitesse du cube 3D</b> 
				</li>
			</ol>

			<div>
				<h2 class="text-primary">Mode édition</h2>
				<div>
					Si vous avez un Rubik's Cube mélangé et que vous voulez trouver la solution pour le résoudre, ce programme peut le résoudre pour vous:
				</div>

				<ul>
					<li>Editez le cube en vue éclatée (1.d) pour obtenir le même cube à l'écran que le votre.</li>
					<li>Vérifiez la validité du cube (1.c)</li>
					<li>Choisissez la méthode de résolution (Friedrich pour la plus rapide) et affichez la solution (7.b)</li>
					<li>Cela affichera les mouvements à reproduire à chaque étape de a résolution que vous pouvez re/jouer à une vitesse adaptée grâce au lecteur d'algorithmes (8)</li>
				</ul>
			</div>

			<div class="row">
				<h2 class="text-primary">Mode apprentissage</h2>
				<div>
					Si vous souhaitez apprendre à résoudre le Rubik's Cube, sachez que le programme affiche la solution ainsi que les différentes étapes de la résolution. 
				</div>

				<div>
					Il permet aussi de naviguer parmi les différents algorithmes de résolution:
				</div>
				<div>
					Si vous mélangez le cube (3) et affichez la solution (7.a/b), vous verrez apparaître (5) les algorithmes utilisés pour résoudre chaque étape de la solution.
				</div>
				<div>
					En cliquant sur une étape de la solution (5), la vue 3D du cube (4) se mettra à jour à l'état initial de l'étape. La vue (1) affichera le cube correspondant en vue éclatée. La vue (6) affiche l'état recherché et atteint en fin d'étape (les cases grises étant considérées comme neutres à cette étape de résolution)
				</div>
				<div>
					Pour connaitre tous les algorithmes nécessaires à la résolution de chaque étapes de chacune des deux méthodes, vous pouvez naviguer parmi eux en choisissant l'algorithme dans la section (7)
				</div>
			</div>



		<Form title='[Rubik Teacher]'/>

		</Container>
	</Box>
});