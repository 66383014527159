export default 
{
    desc: "A software that helps find the steps to solve a scrambled Rubik's Cube, while also offering various learning methods for solving it",
    title2:"Solve the Rubik's Cube and learn how to do",
    desc2: "Rubik Teacher is a program that not only helps solve a scrambled Rubik's Cube through its edit mode, but also, and most importantly, teaches how to solve it using two different methods through the learning mode",
    modeSolve: "Solve mode",
    modeSolveDesc: "Draw the configuration of a scrambled Rubik's Cube, and the program will find the solution to solve it step by step",
    modeLearn: "Learn mode",
    modeLearnDesc: "Learn the methods for solving the Rubik's Cube through 2 techniques based on skill level",
};
  