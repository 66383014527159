import React from "react";

import Menu from "menu";
import Banner from "banner";
import { BannerSoftware } from "./bannerSoftware";

import { Container, Typography, Grid, Paper, Box , Button, LinearProgress, Avatar, Link } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { Paragraph } from "toolbox";
import DownloadIcon from '@mui/icons-material/Download';



const Platform = ({title, icon, mt = 0, style=0}) => {
	return (	
	<Box display="flex" justifyContent='left' alignItems='center' sx= {{ mb:3, mt: mt}}> 
		<img src={icon} width="4%" style={{ marginRight: "10px" }}/>
		<Typography variant={style == 0 ? "platform" : "default" }>{title}</Typography>
	</Box>)
}

const Category = ({title, icon, ml=0}) => {
	return (	
	<Box display="flex" justifyContent='left' alignItems='center' sx= {{ mt:1, ml:ml, mb:1 }}> 
		<img src={icon} width="3%" style={{ marginRight: "10px" }}  />
		<Typography variant="category" >{title}</Typography>
	</Box>)
}

const SoftwareHeader = ({title, group, icon, program, type=0, t}) => {

	const tSoftware = useTranslation("software");

	return (
		<Box
			display="flex"
			alignItems="center" // Centre verticalement
			justifyContent="center" // Centre horizontalement
			sx={{ padding: '16px' }} // Ajoute un peu de padding
		>
			<Box sx={{width:'15%'}}>
				<img  src={icon} className="responsive-image" style={{  marginRight: '16px', height: 'auto' }}/>
			</Box>
			<Box sx={{width:'85%'}}>
				<Typography variant="h1_software" >{title}</Typography>
				<Typography variant="h2_software">{t('title2')}</Typography>
				<Platform title="Windows" icon="/res/software/windows.png" style={1}/>
				<Category title={type == 0 ? tSoftware.t('organization'): 'Rubik\'s Cube'} icon={type ==0 ? "/res/software/organisation.png" : "/res/software/rubik_bw.png" }/>
				<Typography>{t('desc2')}</Typography>
				<Button variant="contained" href={program}  startIcon={<DownloadIcon />}>{tSoftware.t('download')} {title}</Button>
			</Box>
		</Box>)
}


export default (() => {

	const { t, i18n } = useTranslation("software");

	const  tTasky  = useTranslation("tasky");
	const  tRubikTeacher  = useTranslation("rubikTeacher");
	const  tStackmatTimer  = useTranslation("stackmatTimer");


	const SoftQt = ({title, url, desc, icon, bc='lightblue', screenshot, width="20%"})  => {
		return (	
			<Link href={url} underline="none" m={2}>
				<Paper sx= {{ mt:1, ml:3, mb:1 , backgroundColor: bc, padding: 2 }}>
				<Box
				justifyContent="space-between"
					sx={{
						display: 'flex',
						alignItems: 'center', // Aligne verticalement au centre
						//justifyContent: 'flex-start'
						
					}}
				>
					<Box
						sx={{
						//width: "180px", // Ajuste la taille de l'icône en %
						height: 'auto', // Garde les proportions
						}}
					>
						<img src={icon}  style={{ width: '120px', height: 'auto' }} />
					</Box>
					<Box sx= {{ ml: 2 }}>
						<Typography variant="h2_home" sx= {{  mb:3 }}>{title}</Typography>
						<Typography >{desc}</Typography>
					</Box>
					<Box
						sx={{
						width: width, // Ajuste la taille de l'icône en %
					//	height: '100%', // Garde les proportions
						}}
					>
						<img src={screenshot}  style={{ width: '100%', height: 'auto' }} />
					</Box>
				</Box>
			</Paper>
		</Link>)
	}
	






	return <Box>
		<Menu idx={1} />

		<Banner title1={t('title')} image="/res/software/software.png" youtube={true} t={t} page='software'/>

		<Container>
			{/* Windows/Qt */}
			<Platform title="Windows" icon="/res/software/windows.png" t={t}/>
				<Paragraph  text="windowsTxt" t={t}/>
				<Category title={t('organization')} icon="/res/software/organisation.png" />
					<SoftQt title="Tasky" desc={tTasky.t('desc')} icon="/res/tasky/Tasky_ico.png" url="/tasky" screenshot="/res/tasky/Tasky_3_WORK.png" width="30%"/>
				
				<Category title="Rubik's Cube" icon="/res/software/rubik_bw.png" />
					<SoftQt title="Rubik Teacher" desc={tRubikTeacher.t('desc')} icon="/res/rubikTeacher/rubikTeacherIcon.png" url="/rubikTeacher"  screenshot="/res/rubikTeacher/rt_screenshot.webp" width="50%"/>
					<SoftQt title="Virtual Stackmat Timer" desc={tStackmatTimer.t('desc')} icon="/res/stackmatTimer/stackmatTimerIcon.png" url="/virtualStackmatTimer"  screenshot="/res/stackmatTimer/virtualStackmat.jpg" />

			{/* TI-89 */}
			<Platform title="TI-89" icon="/res/software/TI-89.png" mt={8}/>
				<Paragraph  text="ti89.desc" t={t} />
				<SoftQt title={t('ti89.maillon.title')} desc={t('ti89.maillon.desc')} icon="/res/software/ti_maillon.gif" url="https://www.ticalc.org/archives/files/fileinfo/348/34866.html"  bc='#f9e79f'/>
				<SoftQt title={t('ti89.dcedl.title')} desc={t('ti89.dcedl.desc')} icon="/res/software/ti_dcedl.gif" url="https://www.ticalc.org/archives/files/fileinfo/347/34779.html"  bc='#f9e79f' />
		</Container>
	</Box>
});

export {Category, Platform, SoftwareHeader };