export default 
{
  title: "Bienvenue sur mon site Web",
  desc: `Vous trouverez sur ce site:
  <ul>mes applications persos, développées pour Windows<li>des méthodes de résolution du Rubik's Cube`,
  software: "Mes logiciels",
  rubik: "Résolution du Rubik's Cube",



  menu : {
    home : "Accueil",
    software: "Mes logiciels",
    rubik: "Rubik's Cube",
    links: "Liens",
    about: "A propos..."
  },


  form: {
    text: `Une idée d'amélioration ?

Un bug ?

Un encouragement ?`,
send: 'Envoyer',
  }
};