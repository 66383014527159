export default 
{
    title: "Développeur C++/Qt",

    experience: "Expérience Professionnelle",
    skills: "Compétences",
    languages: "Langues",
    formation: 'Formation',
    contact: "Contact",

    techEnv: "Env. technique",
    

    jobs: {
        forsk: {
            missions: 
                [
                    {
                        poste: 'Développeur C++',
                        desc: '',
                        env: '',
                    },
            ]
        },
        csgroup: {
            missions: 
                [
                    {
                        poste: 'Développeur C++/Qt, React',
                        desc: '',
                        env: '',
                    },
                    {
                        poste: 'Développeur C++ / Android / Unreal Engine',
                        desc: '',
                        env: '',
                    },
                ]
        },
        evs: {
            missions: 
                [
                    {
                        poste: 'Développeur C++',
                        desc: '',
                        env: '',
                    },
            ]
        },
        ausy: {
            missions: 
                [
                    {
                        poste: 'Développeur Java',
                        desc: '',
                        env: '',
                    },
            ]
        },
        amadeus: {
            missions: 
                [
                    {
                        poste: 'Développeur C++ / UNIX / Oracle',
                        desc: '',
                        env: '',
                    },
                    {
                        poste: 'Développeur Web Java EE',
                        desc: '',
                        env: '',
                    },
                    {
                        poste: 'Développeur C++ / UNIX / Oracle',
                        desc: '',
                        env: '',
                    },
            ]
        },
    }
};