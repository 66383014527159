export default 
{
    title: "Developer C++/Qt",

    experience: "Experience",
    skills: "Skills",
    languages: "Languages",
    formation: 'Formation',
    contact: "Contact",

    techEnv: "Technical env.",

    jobs: {
        forsk: {
            missions: 
                [
                    {
                        poste: 'C++ Developer',
                        desc: '',
                        env: '',
                    },
            ]
        },
        csgroup: {
            missions: 
                [
                    {
                        poste: ' C++/Qt, React Developer',
                        desc: '',
                        env: '',
                    },
                    {
                        poste: 'C++ / Android / Unreal Engine Developer',
                        desc: '',
                        env: '',
                    },
                ]
        },
        evs: {
            missions: 
                [
                    {
                        poste: 'C++ Developer' ,
                        desc: '',
                        env: '',
                    },
            ]
        },
        ausy: {
            missions: 
                [
                    {
                        poste: 'Java Developer',
                        desc: '',
                        env: '',
                    },
            ]
        },
        amadeus: {
            missions: 
                [
                    {
                        poste: 'C++ / UNIX / Oracle Developer',
                        desc: '',
                        env: '',
                    },
                    {
                        poste: 'Web Java EE Developer',
                        desc: '',
                        env: '',
                    },
                    {
                        poste: 'C++ / UNIX / Oracle Developer',
                        desc: '',
                        env: '',
                    },
            ]
        },
    }
};
  